import React, { useState, useRef } from "react";
import Slider from "react-slick";
import { FcFactoryBreakdown } from "react-icons/fc";

import IndustryBar from "./IndustryBar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
const mockItems = [
  { id: 0, img: "Medical Education.png", title: "Medical Education" },
  { id: 1, img: "Designer.png", title: "Designer" },
  { id: 2, img: "Defence.png", title: "Defence" },
  { id: 3, img: "Real Estate.png", title: "Real Estate" },
  { id: 4, img: "Manufacturing.png", title: "Manufacturing" },
  { id: 5, img: "tourism.jpg", title: "Tourism" },
  { id: 6, img: "Healthcare.png", title: "Healthcare" },
];

function Industrycaro() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const handleMenuClick = (slideIndex) => {
    setCurrentSlide(slideIndex);
    sliderRef.current.slickGoTo(slideIndex);
  };

  const handleNext = () => {
    const nextSlide = (currentSlide + 1) % mockItems.length;
    setCurrentSlide(nextSlide);
    sliderRef.current.slickGoTo(nextSlide);
  };

  const handlePrev = () => {
    const prevSlide = (currentSlide - 1 + mockItems.length) % mockItems.length;
    setCurrentSlide(prevSlide);
    sliderRef.current.slickGoTo(prevSlide);
  };

  const settings = {
    className: "center",
    centerMode: true,
    arrows: false,
    infinite: true,
    centerPadding: "200px",
    slidesToShow: 1,
    speed: 1000,
    adaptiveHeight: true,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    responsive: [
      {
        breakpoint: 640,
        settings: {
          centerPadding: "10px",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 1280,
        settings: {
          centerPadding: "190px",
        },
      },
    ],
  };

  return (

    <div className="w-full md:h-full relative">
    <div className="slider-container w-full mx-auto relative">
      <div className="rounded-xl flex-col justify-center items-center   relative">
        <div className="flex justify-center ">
        <div className="xl:flex gap-2 items-center whitespace-nowrap  mb-5 p-2 justify-center flex bg-gray-100 font-montserrat xl:text-sm text-xs font-semibold  rounded-3xl shadow">
          <i className="text-xl">
            <FcFactoryBreakdown />
          </i>
          Industry Verticals
        </div>
        </div>

        <div className="rounded-xl w-11/12 mx-auto  hidden xl:block ">
        <div className="flex justify-center items-center">
          <IndustryBar
            currentSlide={currentSlide}
            handleMenuClick={handleMenuClick}
            />
            </div>
        </div>
      </div>

      <button
        onClick={handlePrev}
        className="absolute z-20 top-[65%] -translate-y-1/2 left-[65px] -translate-x-full rounded-full border bg-white text-black px-2 py-2 flex items-center xl:top-1/2 xl:left-72 md:left-8"
      >
        <i className="text-xl text-black">
          <FaArrowLeft />
        </i>
      </button>

    <Slider ref={sliderRef} {...settings}>
{mockItems.map((item) => (
  <div key={item.id} className="relative">
    <div className="relative">
      {/* Image */}
      <img
        src={item.img}
        alt={item.title}
        className="mx-auto md:w-[99%] md:h-[720px] h-[190px] w-[90%] gap-4  flex justify-center items-center aspect-auto rounded"
      />
<div className="block md:hidden">

      <div className="absolute bottom-40 inset-0 flex justify-center items-center ">
        <h3 className="text-white bg-black/60 px-2 py- rounded-xl text-sm md:text-xl font-[Lexend] uppercase">
          {item.title}
        </h3>
      </div>
</div>
    </div>
  </div>
))}
</Slider>


      <button
        onClick={handleNext}
        className="absolute z-20 top-[65%] -translate-y-1/2 right-[73px] translate-x-full rounded-full border bg-white text-black px-2 py-2 flex items-center xl:right-72 xl:top-1/2 md:right-8"
      >
        <i className="text-black text-xl">
          <FaArrowRight />
        </i>
      </button>
    </div>
  </div>
  );
}

export default Industrycaro;
