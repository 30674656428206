import React from "react";
import MeshGradient from "./MeshGradient";
import { PiStarFourFill } from "react-icons/pi";
import Lottie from "lottie-react";
import Language from "./Beebox language (1).json"
const Bintogrid = () => {
  return (
    <div id="Bintogrid"  className="mt-8" >

        <div className="flex ml-5 xl:ml-28 w-56 items-center whitespace-nowrap text-base font-semibold gap-2 py-2 px-3 justify-center  bg-gray-100 font-[Montserrat]  rounded-3xl shadow">
              <i className='text-lg text-amber-400'>
                <PiStarFourFill />
              </i>
              OUR VALUES
            </div>
    <div className="md:min-h-screen h-auto flex flex-col justify-center items-center relative md:w-11/12 mx-10 py-7 mt-4">
      {/* Background Mesh Gradient */}
      <div className="hidden md:block absolute inset-0 xl:flex justify-center items-center z-0 overflow-hidden ">
        <MeshGradient />
      </div>

      {/* Grid Content */}
      <div className="container mx-auto px-4 relative "> 
        <div className=" flex flex-col md:grid grid-cols-0  md:grid-cols-3 xl:grid-cols-4 gap-6 mb-10">
          
          {/* Why Us */}
          <div className="rounded-lg  h-64 md:h-60 relative flex justify-center items-center">
            <img
              src="/WHY only.png"
              alt="Why Us"
              className="absolute inset-0 object-cover rounded-lg w-full sm:w-11/12"
            />
          </div>

          {/* Unassailable Privacy */}
          <div className="rounded-lg flex justify-center items-center h-64 md:h-60 w-full bg-white/10 bg-opacity-50 backdrop-blur-sm">
            <img
              src="/Privacy - Text.png"
              alt="Privacy"
              className=" w-8/12 object-cover rounded-lg"
            />
          </div>

          {/* Uncompromising Integrity */}
          <div className="rounded-lg h-60 md:h-60  ">
            <img
              src="/Trust.png"
              alt="Integrity"
              className="w-[390px] h-[250px]  rounded-xl"
            />
          </div>

          {/* Deploy */}
          <div className="shadow-lg rounded-lg row-span-2 w-full h-auto md:h-auto bg-white/10 border border-gray-800 bg-opacity-50 backdrop-blur-sm">
            <img
              src="/Workflow - text.png"
              alt="Deploy"
              className="w-96 mt-10 object-cover rounded-lg"
            />
          </div>

          {/* Go LEAN */}
          <div className="flex justify-center items-center rounded-lg p-1 bg-white/25 w-full h-32 md:h-36">
            <img
              src="/3d leaf.png"
              alt="Go Lean"
              className=" h-28 md:h-32 object-cover rounded-lg"
            />
            <div className="text-white font-lexend  space-y-1 pl-2">
              <span className="text-sm  md:text-lg xl:text-xl font-light">
                Save Resources. <br /> Go sustainable. <br /> Go
              </span>
              <span className="text-sm sm:text-lg md:text-xl xl:text-[22px] font-semibold">LEAN</span>
              <span className="text-sm sm:text-lg md:text-xl xl:text-[25px] font-normal">.</span>
            </div>
          </div>

          {/* Customer Acquisition Cost */}
          <div className="flex justify-center items-center rounded-lg bg-white w-full h-32 md:h-36">
            <img
              src="/Down Arrow.png"
              alt="Acquisition Cost"
              className="h-20 sm:h-28 md:h-32 object-cover rounded-lg"
            />
            <div className="pl-2 gap-1">
              <span className="text-sm sm:text-lg md:text-xl xl:text-[22px] font-light text-black">
                Customer
                <br />
              </span>
              <span className="text-sm md:text-lg  xl:text-xl font-semibold">Acquisition</span>
              <span className="text-sm md:text-lg xl:text-[22px] font-light">Cost.</span>
            </div>
          </div>

          {/* Time-to-Productivity */}
          <div className="flex justify-center items-center bg-black w-full h-32 md:h-36 rounded-lg">
            <img
              src="/Graph.png"
              alt="Time-to-Productivity"
              className="h-20 sm:h-28 md:h-32 object-cover rounded-lg"
            />
            <div className="text-white font-lexend space-y-1 pl-2">
              <span className="text-sm  md:text-lg xl:text-xl font-light">Employee</span><br/>
              <span className="text-sm md:text-lg xl:text-xl font-medium">
                Time-to-Productivity.
              </span>
            </div>
          </div>

          {/* Language Section */}
          <div className="col-span-2  border border-white/10  w-full h-36 sm:h-55 md:h-60 rounded-lg">
            
        <Lottie animationData={Language} loop={true} className=" " />

          </div>

          {/* Customized Solutions */}
          <div className="bg-white flex flex-col justify-center items-center shadow-lg rounded-lg w-full   md:h-60 h-48">
            <img
              src="/tools.png"
              alt="Customized Solutions"
              className="md:w-40 flex justify-center h-28 object-cover rounded-lg  "
            />
            <div className="text-black  font-lexend   whitespace-nowrap">
              <span className="text-sm md:text-xl font-light">
                Customized Solutions
                <br />
              </span>
              <p className="text-sm sm:text-sm md:text-lg font-bold">Easy. Effective.</p>
              <p className="text-sm sm:text-sm md:text-lg font-bold">Every Time.</p>
            </div>
         
          </div>

          {/* Spatial Computing */}
          <div className="rounded-lg ">
            <img
              src="/Spatial.png"
              alt="Spatial Computing"
              className="md:w-full md:h-full w-3/4 h-3/4 object-cover rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Bintogrid;
