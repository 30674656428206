import React from "react";
const Industrybar = ({ currentSlide, handleMenuClick }) => {
  const menuItems = [
    { id: 0, label: "Education" },
    { id: 1, label: "Energy" },
    { id: 2, label: "Defence" },
    { id: 3, label: "Real Estate" },
    { id: 4, label: "Manufacturing" },
    { id: 5, label: "Tourism" },
    { id: 6, label: "Healthcare" },
  ];

  return (
    <nav className=" hidden xl:block w-[90%]   ">
      <div className="w-full md:w-full mx-auto flex flex-wrap justify-center gap-4 items-center">
        <div className="w-full flex justify-center items-center pb-4">
          <div className="text-sm flex flex-wrap justify-center items-center p-1 uppercase border border-white/10 bg-white/10 shadow-md rounded-3xl transition-colors backdrop-blur-sm">
            {menuItems.map((item) => (
              <a
                key={item.id}
                href={item.href}
                onClick={() => handleMenuClick(item.id)}
                className={`block mt-6 lg:inline-block lg:mt-0 text-white 
                            text-xs md:text-sm 2xl:text-base font-light   py-2 px-10 rounded-full mr-2 font-lexend ${
                              currentSlide === item.id
                                ? "bg-orange-500 text-white"
                                : "hover-border"
                            }`}
                style={{ minWidth: "8rem" }}
              >
                {item.label}
              </a>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Industrybar;
